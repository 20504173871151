.subpage{
	.title-header{
		background-size: cover;
		height: 350px;
		background-position: center;
		position: relative;
		@media (max-width: $mobile) {
			height: 250px;
		}
		.title-header-content{
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translate(0%, -50%);
			text-align: center;
			padding: 20px;
		}
		h1{
			color: #fff;
			font-weight: 700;
			font-size: 44px;
			max-width: 700px;
			margin: auto;
			@media (max-width: $mobile) {
				font-size: 32px;
			}
			text-align: center;
			letter-spacing: 4px;
			text-transform: uppercase;
		}
		h3{
			color: #fff;
			font-weight: 400;
			text-align: center;
			margin-top: 20px;
			@media (max-width: $mobile) {
				font-size: 18px;
			}
		}
		.recipe-btn {
			line-height: 15px;
			img{
				height: 15px;
				margin-right: 10px;
			}
		}
	}
	.title-header.large-header{
		height: 700px;
		@media (max-width: $mobile) {
			height: 350px;
		}
	}
	.grey{
		background-color: #FAFAFA;
	}
	.main-content{
		text-align: center;
		margin: 60px 0px;
		background-color: #fff;
		p{
			max-width: 1000px;
			margin: 20px auto 0 auto;
			color: $color2;
			a{
				font-weight: 700;
				color: inherit;
				text-decoration: underline;
			}
		}
		p:first-child{
			margin-top: 0px;
		}
	}
	.slider-content{
		padding: 40px 0px;
		position: relative;
		h2{
			margin-top: 0px;
			text-align: center;
			color: $color2;
			text-transform: uppercase;
			font-size: 38px;
			margin-bottom: 40px;
		}
		p{
			max-width: 1000px;
			margin: 0px auto;
			margin-top: 20px;
			color: $color2;
			&:first-of-type{
				margin-top: 0px;
			}
		}
	}
}

.view-more{
	text-align: center;
	padding: 60px 0px;
	background-color: #FAFAFA;
}

.post-display{
	margin: 60px 0 20px 0;
	@media (max-width: $mobile) {
		margin: 40px 0 20px 0;
	}
	.post{
		@media (max-width: $mobile) {
			padding: 0px;
		}
		.col-5{
			padding: 20px 40px;
			@media (max-width: $mobile) {
				padding: 20px;
			}
		}
		.col-7{
			padding: 20px 40px 20px 0px;
			@media (max-width: $mobile) {
				padding: 20px;
			}
		}
		img{
			width: 100%;
			height: auto;
		}
		padding: 0px 80px;
		.content-holder{
			padding: 20px 0px;
		}
		.post-date{
			color: $color2;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 20px;
		}
		.post-title{
			color: $color2;
			font-weight: 700;
			font-size: 28px;
			letter-spacing: 4px;
			text-transform: uppercase;
		}
		.post-content{
			text-align: left;
			font-size: 18px;
			margin-bottom: 60px;
		}
		.post-btn{
			color: #000;
			background-color: $color1;
			padding: 18px 50px 20px 50px;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 16px;
			text-align: center;
			transition: all 0.3s;
			&:hover, &:focus{
				filter: brightness(85%);
			}
		}
	}
}

.area-interest{
	text-align: center;
	margin: 60px 0 0 0;
	.select{
		.select-holder{
			display: inline-block;
			position: relative;
			cursor: pointer;
			&:after{
				content: '';
				position: absolute;
				top: 5px;
				right: 20px;
				background-image: url('../images/icons/select-down.svg');
				height: 15px;
				width: 20px;
				background-repeat: no-repeat;
				pointer-events: none;
			}
		}
		select {
			position: relative;
			cursor: pointer;
			border: none;
			border-bottom: 2px solid #000;
	    border-radius: 0;
	    font-family: $primary-font;
	    background-color: transparent;
	    outline: none;
	    font-size: 18px;
	    font-weight: 400;
	    color: inherit;
	    padding: 0 60px 15px 20px;
	    line-height: normal;
	    transition: border-color 0.2s ease, outline 0.2s ease;
	    appearance: none;
	    &[disabled], &:disabled{
	      cursor: not-allowed;
	    }
	    // we use invalid like a placeholder
	    &:invalid {
	      color: #000;
	    }
	  }
	}
}
.subscribe{
	text-align: center;
	margin: 60px 0px;
	p{
		color: $color2;
		max-width: 600px;
    margin: 0 auto 20px auto;
	}
	input[type="email"]{
		width: 100%;
		max-width: 600px;
		border: 2px solid #C9C9C9;
		font-size: 16px;
		margin: 20px auto;
		padding: 15px;
		display: block;
	}
	input::placeholder{
		color: #C9C9C9;
	}
	input[type="submit"]{
		background-color: $color1;
		padding: 15px 76px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		border: 0px;
		outline: none;
		cursor: pointer;
		transition: all 0.3s;
		&:hover, &:focus{
			filter: brightness(85%);
		}
	}
	.checkbox-holder{
		width: 100%;
		max-width: 800px;
		margin: 20px auto 0 auto;
		.policy{
			font-size: 12px;
			color: $color2;
			font-weight: 200;
			float: left;
			a{
				font-weight: 400;
				color: $color2;
			}
		}
		.policy-holder{
			width: calc( 100% - 80px);
		}
		input[type="checkbox"]{
			float: left;
			zoom: 1.5;
			outline: none;
		}
	}
}
.subscribe.dark-theme{
	background-color: #000;
	color: #fff;
	margin: 0px;
	padding: 60px 0px;
	p{
		color: #fff;
	}
	input[type="email"]{
		border: 2px solid #000;
	}
	input::placeholder{
		color: #C9C9C9;
	}
	.checkbox-holder{
		.policy{
			color: #fff;
			a{
				color: $color1;
			}
		}
	}
}


.image-slideshow-bar{
	margin: 60px 0px;
	.container{
		@media (max-width: $mobile) {
			padding: 0px;
		}
	}
	.image-slideshow-holder{
		margin: 0px;
		position: relative;
		.image-slideshow{
			.slideshow-image{
				height: 500px;
				@media (max-width: $mobile) {
					height: 250px;
				}
				width: 100%;
				background-size: cover;
				background-position: center;
			}
		}
		.slideshow-content{
			background-color: #FAFAFA;
			padding: 20px 40px;
			.slideshow-numbers{
				font-size: 22px;
				color: #BABABA;
				span{
					color: $color2;
				}
			}
			.slideshow-title{
				font-size: 28px;
				color: $color2;
				margin-top: 30px;
				text-transform: uppercase;
				font-weight: 400;
			}
			p{
				text-align: left;
				font-size: 18px;
			}
		}
		.image-slideshow-prev{
			position: absolute;
			top: 525px;
			right: 140px;
			cursor: pointer;
			@media (max-width: $mobile) {
				top: 270px;
				right: 100px;
			}
			img{
				height: 20px;
			}
		}
		.image-slideshow-next{
			position: absolute;
			top: 525px;
			right: 80px;
			cursor: pointer;
			@media (max-width: $mobile) {
				top: 270px;
				right: 40px;
			}
			img{
				height: 20px;
			}
		}
	}
}

.related{
	margin: 60px 0px;
	padding: 20px 0px;
	background-color: #FAFAFA;
	.col-3{
		padding: 20px 0px 20px 40px;
		@media (max-width: $mobile) {
			padding: 20px;
		}
	}
	.col-9{
		padding: 20px 40px;
		@media (max-width: $mobile) {
			padding: 20px;
		}
	}
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.related-content{
		padding: 20px 0px;
	}
	.related-title{
		color: $color2;
		font-weight: 700;
		font-size: 22px;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
	.related-subtitle{
		color: #BABABA;
		font-weight: 400;
		font-size: 18px;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin-bottom: 15px;
	}
	.related-btn{
		color: #000;
		background-color: $color1;
		padding: 18px 50px 20px 50px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		transition: all 0.3s;
		display: inline-block;
		margin-top: 40px;
		&:hover, &:focus{
			filter: brightness(85%);
		}
	}
}


.video-bar{
	margin: 60px 0px;
  .video-holder{
  	position: relative;
  	.video-overlay{
  		transition: all 0.3s;
  		position: absolute;
  		top: 0;
  		left: 0;
  		right: 0;
  		bottom: 0;
  		background-repeat: no-repeat;
  		background-size: cover;
  		z-index: 999;
  		cursor: pointer;
  		img{
  			position: absolute;
  			top: 50%;
  			left: 50%;
  			height: 120px;
  			transform: translate(-50%, -50%);
  			pointer-events: none;
  			z-index: 9999;
  			@media (max-width: $mobile) {
  				height: 70px;
  			}
  		}
  	}
  	.video-overlay.hide{
  		opacity: 0;
  		pointer-events: none;
  	}
  	video{
    	width: 100%;
    	margin: 0px;
    }
  	@media (max-width: $mobile) {
  		margin: 0px;
  	}
  }
}

.testimonials{
	margin: 60px 0px;
	padding: 40px 0px;
	.testimonial-holder{
		padding: 40px 80px;
		@media (max-width: $mobile) {
			padding: 20px;
		}
		border: 5px solid $color1;
		position: relative;
		&:before{
			content: '';
			height: 10px;
			width: 200px;
			position: absolute;
			top: -5px;
			left: 50%;
			background-color: #fff;
			transform: translate(-50%, 0%);
		}
		.quote{
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			img{
				height: 40px;
			}
		}
		.testimonial-quote{
			color: $color2;
			font-weight: 300;
			letter-spacing: 1px;
		}
		.testimonial-author{
			color: $color2;
			font-weight: 700;
			font-size: 18px;
			margin-top: 40px;
			text-align: center;
		}
	}
}

.related-products{
	margin: 60px 0px;
	.related-products-title{
		color: $color2;
		font-weight: 700;
		font-size: 26px;
		text-align: center;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
	.related-product-holder{
		text-align: center;
		img{
			width: 100%;
			padding: 0px 20px;
		}
		.related-product-title{
			color: $color2;
			font-weight: 700;
			font-size: 18px;
			text-align: center;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		.related-product-content{
			color: $color2;
			margin-top: 10px;
		}
	}
}

.whats-new{
	margin: 60px 0px;
	@media (max-width: $mobile) {
		margin: 40px 0px;
	}
	.whats-new-holder{
		height: 360px;
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		transition: all 0.3s;
		&:hover, &:focus{
			filter: brightness(85%)
		}
		.whats-new-content{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #fff;
			font-weight: 700;
			font-size: 18px;
			text-align: center;
			width: 100%;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
	}
}

.search.search-results{
	margin: 0px;
	a{
		color: inherit;
	}
}

.search-results{
	margin: 60px 0px;
	.search-result-holder{
		text-align: center;
		img{
			width: 100%;
			padding: 0px 20px;
			transition: all 0.3s;
		}
		&:hover, &:focus{
			img{
				filter: brightness(85%);
			}
		}
		.search-result-title{
			color: $color2;
			font-weight: 700;
			font-size: 16px;
			text-align: center;
			letter-spacing: 1px;
			text-transform: uppercase;
			margin-top: 10px;
		}
		.search-result-content{
			color: $color2;
			margin-top: 10px;
		}
	}
}

.search-find{
	&-image{
		img{
			width: 100%;
			height: 160px;
			object-fit: cover;
		}
	}
	&-title{
		font-size: 20px;
	}
}


.map-holder{
	margin: 60px 0 0 0;
	#map{
		height: 480px;
	}
}


.product-features{
	margin: 60px 0px;
	.product-features-image{
		img{
			width: 400px;
			@media (max-width: $mobile) {
				width: 100%;
			}
			margin: auto;
			display: block;
		}
	}
	.product-features-accord-holder{
		margin: 0px 120px;
		@media (max-width: $mobile) {
			margin: 0px;
		}
		.accordion {
		  cursor: pointer;
		  padding: 20px;
		  width: 100%;
		  border: none;
		  border-bottom: 2px solid #707070;
		  text-align: left;
		  outline: none;
		  font-size: 16px;
		  transition: 0.3s;
		  position: relative;
		  background-color: transparent;
		}

		.accordion:after {
		  content: '';
		  background-image: url('../images/icons/select-down-dark.svg');
		  height: 15px;
		  width: 15px;
		  background-repeat: no-repeat;
		  position: absolute;
		  top: 50%;
		  transform: translate(0%, -50%);
		  right: 20px;
		  // transition: all 0.3s;
		}

		.active:after {
		  transform: translate(0%, -50%) rotate(180deg);
		}

		.panel {
		  padding: 0px 20px;
		  background-color: white;
		  max-height: 0;
		  overflow: hidden;
		  transition: max-height 0.2s ease-out;
		  text-align: left;
		  p{
		  	font-size: 16px;
		  	text-align: left;
		  }
		}
	}
}

.related-slider{
	margin: 60px 0;
	@media (max-width: $mobile) {
		.slick-arrow{
			display: none !important;
		}
	}
	.three-slider-prev{
		left: -60px;
	}
	.three-slider-next{
		right: -60px;
	}
	.slider-holder{
		height: 100%;
		padding: 0 20px;
		@media (max-width: $mobile) {
			padding: 0px;
		}
		img{
			width: 100%;
		}
	}
	.slider-text{
		text-align: center;
		color: $color2;
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding: 20px 0px;
	}
}

.local-suppliers{
	margin: 60px 0;
	display: none;
	.title{
		margin-top: 0;
    text-align: center;
    color: $color2;
    text-transform: uppercase;
    font-size: 38px;
    margin-bottom: 40px;
    font-weight: 700;
	}
	.local-suppliers-accord-holder{
		margin: 0px 120px;
		@media (max-width: $mobile) {
			margin: 0px;
		}
		.accordion {
		  cursor: pointer;
		  padding: 20px;
		  width: 100%;
		  border: none;
		  border-bottom: 2px solid #707070;
		  text-align: left;
		  outline: none;
		  font-size: 18px;
		  transition: 0.3s;
		  position: relative;
		  background-color: transparent;
		  .btn{
		  	font-size: 12px;
		  	padding: 10px 20px;
		  }
		  .distance{
		  	float: right;
		  	margin-right: 60px;
		  	position: relative;
		  	@media (max-width: $mobile) {
					float: none;
					margin-top: 5px;
					display: block;
				}
		  	.btn{
			  	font-size: 12px;
			  	padding: 10px 20px;
			  	position: absolute;
			  	right: 100%;
			  	top: 50%;
			  	margin-right: 40px;
			  	width: 130px;
			  	transform: translate(0%, -50%);
			  }
		  }
		}

		.accordion:after {
			content: '\002B';
		  color: #777;
		  font-weight: bold;
		 	position: absolute;
		 	right: 40px;
		 	top: 50%;
		 	transform: translate(0%, -50%);
		  margin-left: 5px;
		  font-size: 22px;
		}

		.active:after {
		  content: "\2212";
		}

		.panel {
		  padding: 0 10px;
		  max-height: 0;
		  overflow: hidden;
		  transition: max-height 0.2s ease-out;
		  text-align: left;
		  a{
		  	color: inherit;
		  	text-decoration: underline;
		  }
		  p{
		  	font-size: 16px;
		  	text-align: left;
		  }
		  ul{
		  	padding: 0px;
		  	list-style-type: none;
		  }
		}
	}
}


.national-suppliers{
	display: none;
	margin: 60px 0 0 0;
	padding: 60px 0;
	background-color: #FAFAFA;
	.title{
		margin-top: 0;
    text-align: center;
    color: $color2;
    text-transform: uppercase;
    font-size: 38px;
    margin-bottom: 40px;
    font-weight: 700;
	}
	.local-suppliers-accord-holder{
		margin: 0px 120px;
		@media (max-width: $mobile) {
			margin: 0px;
		}
		.accordion {
		  cursor: pointer;
		  padding: 20px;
		  width: 100%;
		  border: none;
		  border-bottom: 2px solid #707070;
		  text-align: left;
		  outline: none;
		  font-size: 18px;
		  transition: 0.3s;
		  position: relative;
		  background-color: transparent;
		  .btn{
		  	font-size: 12px;
		  	padding: 10px 20px;
		  }
		  .distance{
		  	float: right;
		  	margin-right: 60px;
		  	position: relative;
		  	@media (max-width: $mobile) {
					float: none;
					margin-top: 5px;
					display: block;
				}
		  	.btn{
			  	font-size: 12px;
			  	padding: 10px 20px;
			  	position: absolute;
			  	right: 100%;
			  	top: 50%;
			  	margin-right: 40px;
			  	width: 130px;
			  	transform: translate(0%, -50%);
			  }
		  }
		}

		.accordion:after {
			content: '\002B';
		  color: #777;
		  font-weight: bold;
		 	position: absolute;
		 	right: 40px;
		 	top: 50%;
		 	transform: translate(0%, -50%);
		  margin-left: 5px;
		  font-size: 22px;
		}

		.active:after {
		  content: "\2212";
		}

		.panel {
		  padding: 0 10px;
		  max-height: 0;
		  overflow: hidden;
		  transition: max-height 0.2s ease-out;
		  text-align: left;
		  a{
		  	color: inherit;
		  	text-decoration: underline;
		  }
		  p{
		  	font-size: 16px;
		  	text-align: left;
		  }
		  ul{
		  	padding: 0px;
		  	list-style-type: none;
		  }
		}
	}
}




// Table Styles
.panel{
	table{
		width: 100%;
    margin: 40px 0px;
   	border-collapse: collapse;
    border: 1px solid #ccc;
    tbody{
    	tr:first-child{
    		background-color: #000;
    		td{
    			p{
    				color: #fff;
        		text-transform: uppercase;
    			}
    		}
    	}
    	td{
    		border: 1px solid #ccc;
    		padding: 10px;
    		p{
    			a{
    				color: inherit;
    				text-decoration: underline;
    				font-weight: 700;
    			}
    		}
    	}
    }
	}
}

.simple-sitemap-page{
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	text-align: left;
	margin-left: 20px;
	li{
		padding: 6px 0px;
		position: relative;
		&:after{
			content: '';
			position: absolute;
			left: -20px;
			top: 50%;
			transform: translate(0%, -50%);
			background-image: url('../images/file.svg');
			height: 15px;
			width: 15px;
			background-repeat: no-repeat;
		}
		a{
			color: inherit;
			font-size: 16px;
		}
	}
	.page_item_has_children{
		padding-bottom: 0px;
		&:after{
			top: 16px;
		}
	}
	.children{
		margin: 0px;
		padding: 0px;
		padding-top: 6px;
		list-style-type: none;
		li{
			padding: 6px 0px;
			margin-left: 15px;
			a{
				color: inherit;
				font-size: 16px;
			}
		}
	}
}

.margin-top-none{
	margin-top: 0px;
}

.feature-holder{
	margin: $spacing * 1.5;
	margin-left: 0px;
	padding-left: 70px;
	position: relative;
	&:after{
		content: '';
		position: absolute;
		top: 0%;
		left: 0px;
		height: 50px;
		width: 50px;
		background-size: cover;
		background-repeat: no-repeat;
		background-image: url('../images/tick.png');
	}
	.feature-title{
		font-size: 18px;
	}
	.feature-content{
		p{
			margin: 5px 0px 0px 0px;
		}
	}
}

.wpcf7-response-output , .grecaptcha-badge {
    display: none !important;
}
.popup-with-zoom-anim{
	visibility: hidden;
}

.popup-download{
	text-align: center;
	padding: 40px;
	.popup-download-title{
		color: #494949;
    font-weight: 700;
    font-size: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
	}
	.popup-download-content{
		margin-bottom: 40px;
	}
	.popup-download-info{
		font-size: 14px;
	}
	.btn-holder{
		max-width: 600px;
		margin: auto;
	}
	.btn{
		margin: 10px 0px;
	}
}
#campaigns_filter_search_more_holder , #events_filter_search_more_holder, #downloads_filter_search_more_holder{
	display: none;
	.btn{
		cursor: pointer;
	}
}

.radius-edit{
	display: none;
	padding: 40px 0px;
	.radius-edit-title{
		float: left;
		line-height: 22px;
	}
	.checkboxes{
		float: right;
		ul{
			margin: 0px;
			padding: 0px;
			list-style-type: none;
			li{
				display: inline-block;
				padding: 0px 20px 0px 28px;
				line-height: 22px;
				position: relative;
				.radio{
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0%, -50%);
					height: 20px;
					width: 20px;
					border: 1px solid #000;
					border-radius: 20px;
					display: inline-block;
					cursor: pointer;
				}
				.active.radio{
					&:after{
						content: '';
						position: absolute;
						height: 10px;
						width: 10px;
						background-color: #000;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						border-radius: 10px;
					}				
				}
			}
		}
	}

}


.recripe_features {
	background-color: #fff;
	.col-4 {
		height: 100%;
	}
	.recripe_feature {
		position: relative;
		height: 100%;
		img {
			float: left;
	    height: 85px;
	    width: 85px;
	    object-fit: cover;
		}
		p {
			text-align: left;
			margin: 0;
			position: absolute;
			left: 100px;
			top: 50%;
			transform: translate(0%, -50%);
			font-size: 16px;
    	font-weight: 200;
		}
	}
}




