.top-btn{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  @media (max-width: 810px) {
    display: block;
  }
  .top-btn-left, .top-btn-right{
    width: 50%;
    background-color: red;
    float: left;
    font-size: 12px;
    font-weight: 700;
    color: #000;
    display: inline-block;
    text-transform: uppercase;
    transition: all 0.3s;
    text-align: center;
    &:hover, &:focus{
      text-decoration: none;
      filter: brightness(85%);
    }
    img{
      width: 18px;
      height: 18px;
      margin-left: 10px;
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }
  .top-btn-left{
    background-color: #D4D6D6;
    padding: 10px 35px 10px 25px;
  }
  .top-btn-right{
    background-color: $color1;
    padding: 10px 45px 10px 25px;
  }
}



.nav{
  padding: 30px 0px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  @media (max-width: 810px) {
    padding: 60px 0px 30px 0px;
  }
  @media (max-width: $mobile) {
    padding: 50px 0px 20px 0px;
  }
  .container{
    position: relative;
    @media (min-width: 1650px) {
      width: 1600px;
    }
  }
  .nav-left{
    float: left;
    .nav-toggle{
      display: none;
      @media (max-width: $mobile) {
        display: block;
      }
    }
  }
  .header-btn-holder{
    @media (max-width: 810px) {
      display: none;
    }
    font-size: 11px;
    font-weight: 700;
    color: #000;
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    margin-right: 10px;
    transition: all 0.3s;
    &:hover, &:focus{
      text-decoration: none;
      filter: brightness(85%);
    }
    img{
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }
  .header-btn-holder.grey{
    background-color: #D4D6D6;
    padding: 10px 35px 10px 25px;
    img{
      right: 10px;
    }
  }
  .header-btn-holder.yellow{
    background-color: $color1;
    padding: 10px 45px 10px 25px;
    img{
      right: 15px;
    }
  }

  .logo{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img{
      height: 50px;
      @media (max-width: $mobile) {
        height: 40px;
      }
    }
  }


  .nav-right{
    float: right;
    padding-top: 3px;
    .nav-toggle{
      @media (max-width: $mobile) {
        display: none;
      }
    }
  }
  .nav-toggle{
    float: right;
    height: 2em;
    width: 2em;
    margin-left: 20px;
    position: relative;
    cursor: pointer;
    img{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 20px;
      width: 20px;
    }
  }
  .search {
    float: left;
    width: 150px;
    height: 2em;
    padding: 0;
    margin: 0;
    line-height: 2em;
    position: relative;
    transition: all .25s linear;
    input {
      width: 125px;
      height: 2em;
      line-height: 1em;
      padding: 0 0 0 .5em;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 1em;
      color: #000;
      float: left;
      transition: all .25s linear;
    }
    a {
      width: 2em;
      height: 2em;
      line-height: 2em;
      padding: 0;
      color: #000;
      text-align: center;
      text-decoration: none;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transition: all .25s linear;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url('../images/icons/search.svg');
        height: 20px;
        width: 20px;
      }
      &:hover {
        color: #eee;
      }
    }
    &.collapsed {
      width: 2em;
      margin-left: 10em;
      input {
        width: 0;
      }
    }
  }
}

.nav-overlay{
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  z-index: 999999;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  &-holder{
    width: 30%;
    height: 100%;
    background-color: rgba($color1, 0.9);
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
  ul{
    position: absolute;
    top: 0%;
    left: 0%;
    // transform: translate(-50%, -0%);
    list-style: none;
    // text-align: center;
    padding: 0px;
    margin: $spacing*2;
    li{
      a{
        font-weight: 700;
        display: block;
        padding: 10px;
        color: #000;
        text-decoration: none;
        font-size: 22px;
        text-transform: uppercase;
        &:hover, &:focus{
          filter: brightness(85%);
        }
      }
    }
    .exit{
      padding: 10px;
      font-size: 25px;
      cursor: pointer;
      color: #000;
      &:hover, &:focus{
        filter: brightness(85%);
      }
    }
  }
}
