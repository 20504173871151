.stockist-upload-container {
    .stockist-upload-form {
        background-color: #fcce01;
        margin: 0 auto;
        padding: 16px;
        width: 300px;
    
        .form-element {
            input {
                font-size: 16px;
            }
    
            .upload_btn {
                background-color: black;
                border: none;
                color: white;
                margin-top: 16px;
                outline: none;
                padding: 5px;
                width: 100%;
            }
        }
    }

    ul {
        margin: 16px auto;
        width: 300px;

        li {
            margin: 16px 0;

            span {
                font-weight: bold;
            }
        }
    }
}
