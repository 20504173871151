.image-slider{
	div{
		height: 600px;
		img{
			width: 100%;
			height: 600px;
			object-fit: cover;
			object-position: center;
		}
	}
}


.full-slider{
	div{
		height: calc(100vh - 95px);
		img{
			height: calc(100vh - 95px);
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}

.slider-content{
	.slider-holder{
		margin: 40px 0 0 0;
		position: relative;
		.slick-arrow.slick-hidden{
			// display: block !important;
		}
	}
}

.three-slider{
	visibility: hidden;
	a{
		outline: none;
		display: block;
		transition: all 0.3s;
		&:hover, &:focus{
      text-decoration: none;
      filter: brightness(85%);
    }
	}
	.slider-holder{
		margin: 10px;
		height: 240px;
		.slider-title{
			padding: 40px;
			text-align: center;
			color: #fff;
			font-size: 22px;
			text-transform: uppercase;
		}
	}
}
.three-slider-prev{
	position: absolute;
	left: -40px;
	top: 50%;
	transform: translate(0%, -50%);
	cursor: pointer;
	outline: none;
	img{
		height: 25px;
	}
}
.three-slider-next{
	position: absolute;
	right: -40px;
	top: 50%;
	transform: translate(0%, -50%);
	cursor: pointer;
	outline: none;
	img{
		height: 25px;
	}
}
.two-slider{
	visibility: hidden;
	a{
		outline: none;
		display: block;
		transition: all 0.3s;
		&:hover, &:focus{
      text-decoration: none;
      filter: brightness(85%);
    }
	}
	.slider-holder{
		margin: 10px;
		height: 240px;
		background-position: center;
		background-size: cover;
		.slider-title{
			padding: 40px;
			text-align: center;
			color: #fff;
			font-size: 22px;
			text-transform: uppercase;
		}
	}
}
.two-slider-prev{
	position: absolute;
	left: -40px;
	top: 50%;
	transform: translate(0%, -50%);
	cursor: pointer;
	outline: none;
	img{
		height: 25px;
	}
}
.two-slider-next{
	position: absolute;
	right: -40px;
	top: 50%;
	transform: translate(0%, -50%);
	cursor: pointer;
	outline: none;
	img{
		height: 25px;
	}
}

.slick-initialized{
	visibility: visible;
}
