.footer{
	background-color: #000;
	color: #fff;
	padding: 60px 0px;
	 @media (max-width: $mobile) {
	 	padding: 20px 0px;
	 	text-align: center;
	 }
	 .col-4, .col-12{
	 	padding: 0px 20px;
	 }
	.footer-links{
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		li{
			display: inline-block;
			padding: 0px 10px;
			position: relative;
			line-height: 30px;
			&:after{
				content: '|';
				position: absolute;
				right: -2px;
				top: 4px;
				color: #fff;
				font-size: 12px;
			}
			&:first-child{
				padding-left: 0px;
			}
			&:last-child{
				&:after{
					display: none;
				}
			}
			a{
				color: #fff;
				font-size: 12px;
				font-weight: 300;
			}
		}
	}
	.socialmedia{
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		text-align: center;
		li{
			display: inline-block;
			padding: 0px 5px;
			position: relative;
			img{
				height: 30px;
			}
		}
	}
	p{
		text-align: right;
		@media (max-width: $mobile) {
			text-align: center;
		}
		font-size: 12px;
		font-weight: 300;
		margin: 0 0 10px 0;
		a{
			color: #fff;
		}
		&:last-child{
			margin: 0px;
		}
	}
}