.experience-live {
    background-image: url(https://www.mccainfoodservice.co.uk/wp-content/themes/mccain/images/experience-live/background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &-header {
        height: 800px;
        overflow: hidden;
        position: relative;

        video {
            width: 100%;
        }

        .header-overlay {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            img {
                height: 700px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
            }
        }

        @media (max-width: 1450px) {
            height: 600px;

            .header-overlay {
                img {
                    height: 500px;
                }
            }
        }

        @media (max-width: 1100px) {
            height: 500px;

            .header-overlay {
                img {
                    height: 400px;
                }
            }
        }

        @media (max-width: 900px) {
            height: 400px;

            .header-overlay {
                img {
                    height: 300px;
                }
            }
        }

        @media (max-width: 750px) {
            height: 300px;

            .header-overlay {
                img {
                    height: 250px;
                }
            }
        }

        @media (max-width: 550px) {
            height: 200px;

            .header-overlay {
                img {
                    height: 200px;
                }
            }
        }

        @media (max-width: 375px) {
            height: 150px;

            .header-overlay {
                img {
                    height: 150px;
                }
            }
        }
    }

    &-body {
        .card-seperator {
            background-color: #fcce01;
            height: 3px;
            margin: 30px;
        }

        .experience-live-statement {
            margin: 50px 0;

            &-title {
                p {
                    color: #fcce01;
                    font-size: 38px;
                    line-height: 38px;
                }
            }

            &-body {
                p {
                    color: white;
                    font-size: 27px;
                    line-height: 27px;
                }
            }

            @media (max-width: $small-screen) {
                &-title {
                    p {
                        font-size: 28px;
                        line-height: 28px;
                    }
                }

                &-body {
                    p {
                        font-size: 17px;
                        line-height: 17px;
                    }
                }
            }
        }

        .experience-live-card {
            &-img {
                img {
                    width: 100%;
                }
            }

            &-body {
                background-position: center;
                background-repeat: no-repeat;
                background-size: 200px;
                display: -webkit-box;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-direction: column;
                -webkit-box-pack: center;
                justify-content: center;

                &-title p,
                &-link {
                    margin: 10px 0;
                }

                &-title {
                    p {
                        color: #fcce01;
                        font-size: 38px;
                        line-height: 38px;
                    }
                }

                &-link {
                    text-align: center;

                    a {
                        color: white;
                        font-size: 32px;
                        line-height: 32px;
                        text-transform: uppercase;
                    }
                }
            }

            @media (max-width: $small-screen) {
                &-body {
                    background-size: 150px;

                    &-title {
                        p {
                            font-size: 28px;
                            line-height: 28px;
                        }
                    }

                    &-link {
                        a {
                            font-size: 22px;
                            line-height: 22px;
                        }
                    }
                }
            }

            @media (max-width: $mobile) {
                &-body {
                    height: 250px;
                }
            }
        }

        .experience-live-day-out-link-container {
            margin: 75px 0;
            text-align: center;


            a {
                color: #fcce01;
                font-size: 62px;
                line-height: 62px;
                text-decoration: underline;
            }

            @media (max-width: $small-screen) {
                a {
                    font-size: 52px;
                    line-height: 52px;
                }
            }

            @media (max-width: $mobile) {
                margin: 25px 0 75px;

                a {
                    font-size: 42px;
                    line-height: 42px;
                }
            }
        }
    }
}

#experience-live-day-out-video-container {
    text-align: center;

    video {
        width: 800px;
    }

    @media (max-width: 920px) {
        video {
            width: 100%;
        }
    }
}
