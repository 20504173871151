.contact-form{
	.subscribe{
		float: left;
		margin: 0px;
	}
	margin: 60px 0px;
	text-align: center;
	.wpcf7-response-output{
		display: block !important;
		border: none !important;
	}
	p{
		margin: 0px;
	}
	form{
		margin: auto;
		max-width: 550px;
		input{
			width: 100%;
			border: 2px solid #C9C9C9;
			font-size: 16px;
			margin: 0 0 20px 0;
			padding: 15px;
			display: block;
		}
		input[type="submit"], button{
			outline: none;
			border: none;
			padding: 20px 80px;
			cursor: pointer;
		}
		.checkbox-holder{
			text-align: left;
			margin: 20px auto 0 auto;
			.policy{
				font-size: 12px;
				color: $color2;
				font-weight: 200;
				a{
					font-weight: 400;
					color: $color2;
				}
			}
			input[type="checkbox"]{
				float: left;
				zoom: 1.5;
				outline: none;
				width: auto;
				margin: 4px 10px 0 0;
			}
		}
	}
}
.search .search-form{
	margin: auto;
	max-width: 550px;
	margin-bottom: 40px;
	margin-top: 20px;
	input{
		width: 100%;
		border: 2px solid #C9C9C9;
		font-size: 16px;
		margin: 20px 0px;
		padding: 15px;
		display: block;
	}
	button{
		outline: none;
		border: none;
		padding: 10px 40px;
		cursor: pointer;
		background-color: $color1;
		color: #000;
		font-weight: 400;
		font-size: 16px;
	}
}
.search-form{
	margin: 60px 0px;
	@media (max-width: $mobile) {
		margin: 40px 0px;
	}
	text-align: center;
	form{
		margin: auto;
		max-width: 550px;
		input{
			width: 100%;
			border: 2px solid #C9C9C9;
			font-size: 16px;
			margin: 0 0 20px 0;
			padding: 15px;
			display: block;
		}
		button{
			outline: none;
			border: none;
			padding: 20px 80px;
			cursor: pointer;
		}
		.checkbox-holder{
			text-align: left;
			margin: 20px auto 0 auto;
			.policy{
				font-size: 12px;
				color: $color2;
				font-weight: 200;
				a{
					font-weight: 400;
					color: $color2;
				}
			}
			input[type="checkbox"]{
				float: left;
				zoom: 1.5;
				outline: none;
				width: auto;
				margin: 4px 10px 0 0;
			}
		}
		.select-holder{
			display: inline-block;
			position: relative;
			cursor: pointer;
			width: 100%;
			&:after{
				content: '';
				position: absolute;
				top: 18px;
				right: 20px;
				background-image: url('../images/icons/select-down-dark.svg');
				height: 15px;
				width: 20px;
				background-repeat: no-repeat;
				pointer-events: none;
			}
		}
		select {
			width: 100%;
			cursor: pointer;
			border: 2px solid #C9C9C9;
	    border-radius: 0;
	    padding: 15px;
	    font-size: 16px;
			margin: 0 0 20px 0;
	    font-family: $primary-font;
	    background-color: transparent;
	    outline: none;
	    font-weight: 400;
	    color: inherit;
	    line-height: normal;
	    transition: border-color 0.2s ease, outline 0.2s ease;
	    appearance: none;
	    &[disabled], &:disabled{
	      cursor: not-allowed;
	    }
	    // we use invalid like a placeholder
	    &:invalid {
	      color: #757575;
	    }
	  }
	}
}
.search-btns{
	margin: auto;
	max-width: 550px;
	margin-top: 40px;
	div{
		display: block;
		color: #000;
		background-color: #EEEEEE;
		padding: 18px 50px 20px 50px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		transition: all 0.3s;
		margin-bottom: 20px;
		cursor: pointer;
		&:hover, &:focus{
			filter: brightness(85%);
		}
		&:nth-child(2n){
			background-color: #000;
			color: #fff;
		}
	}
}
.no-match{
	text-align: center;
	width: 100%;
}
.parsley-error{
	margin: 0 0 10px 0 !important;
}
.parsley-errors-list{
	margin-bottom: 20px !important;
}


.area-interest{
	.select-holder{
		visibility: hidden;
		pointer-events: none
	}
}
