.surecrisp_nav {
  background-color: #000;
  padding: 10px;
  text-align: center;
  height: 60px;
  img {
    height: 100px;
    position: relative;
    z-index: 99;
    @media (max-width: 768px) {
      height: 70px;
    }
  }
}
.surecrsip {
	.surecrsip_holder {
		height: 78.3vh;
		background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    img {
    	position: absolute;
    	height: 700px;
    	left: 50%;
    	transform: translate(-50%, 0%);
    	top: -115px;
    	@media (max-width: 600px) {
    		left: 0;
    		right: 0;
    		width: 100%;
    		height: auto;
    		transform: none;
    		top: -50px;
    	}
    }
    ul {
    	margin: 0px;
    	padding: 0px;
    	list-style-type: none;
    	position: absolute;
  		bottom: 20px;
  		left: 50%;
  		transform: translate(-50%, 0%);
      width: 100%;
      text-align: center;
      @media (max-width: 564px) {
        bottom: 10px;
      }
    	li {
    		display: inline-block;
    		// width: 240px;
        font-weight: 700;
    		background-color: $color1;
    		margin: 0 20px;
    		transition: all 0.3s;
        @media (max-width: 564px) {
          margin-bottom: 10px;
        }
    		&:hover {
    			filter: brightness(85%);
    		}
    		a {
    			color: #000;
    			text-align: center;
    			display: block;
    			font-size: 16px;
          padding: 18px 30px 20px 30px;
          @media (max-width: 564px) {
            font-size: 12px;
            line-height: 20px;
            padding: 10px;
          }
    		}
    	}
    }
	}
}


.sign-overlay {
  position: relative; 
  .sign-overlay-image {
    position: absolute;
    right: 0;
    top: -100px;
    @media (max-width: 768px) {
      right: 5px;
      top: -60px;
    }
    @media (max-width: 600px) {
      right: 15px;
      top: -60px;
    }
    img {
      width: 440px;
      @media (max-width: 1200px) {
        width: 375px;
      }
      @media (max-width: 768px) {
        width: 280px;
      }
      @media (max-width: 600px) {
        width: 260px;
      }
    }
  }
}

.surecrisp_impression, .surecrisp_delivery {
  .btn {
    padding: 0;
    margin-top: 20px;
    a {
      color: #000;
      display: block;
      padding: 18px 50px 20px 50px;
    }
  }
  .search-results-title {
    max-width: 900px;
    margin: 10px auto;
  }
  .search-result-subtitle {
    color: #494949;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 10px;
  }
  .search-result-excerpt {
    color: #494949;
    font-size: 14px;
    text-align: center;
    max-width: 200px;
    margin: 10px auto;
  }

  .bounderies {
    img {
      width: 100%;
    }
    p {
      text-align: left !important ;
    }
  }

  .delivery_options {
    background-color: #000;
    color: #fff;
    h3 {
      color: #f5b31e;
      margin: 10px 0 0 0;
    }
    p {
      text-align: left !important;
      color: #fff;
      height: 80px;
    }
    .phaseone {
      position: relative;
      .arrow {
        position: absolute;
        right: 0;
        top: calc(50% + 50px);
        width: 100px;
        transform: translate(50%, -50%);
        @media (max-width: $mobile) {
          top: auto;
          bottom: 0;
          right: 50%;
          transform: translate(50%, 0%);
        }
      }
      .phase_img {
        padding: 50px;
        width: 100%;
      }
    }
    .phasetwo img {
      padding: 30px;
      width: 100%;
    }
    .phasethree {
      position: relative;
      .arrow {
        position: absolute;
        left: 20px;
        top: calc(50% + 50px);
        width: 100px;
        transform: translate(-50%, -50%);
        @media (max-width: $mobile) {
          top: -25px;
          left: 50%;
          transform: translate(-50%, 0%);
        }
      }
      .phase_img {
        padding: 80px;
        width: 100%;
      }
    }
  }
}

.surecrisp_info {
  background-color: #fcce01;
  p {
    text-align: left;
  }
  sup {
    font-size: 12px !important;
  }
  ul {
    font-size: 20px;
    color: #000;
    padding-left: 22px;
    @media (max-width: $mobile) {
      font-size: 16px;
    }
    li {
      padding-bottom: 10px;
    }
  }
  img, iframe {
    width: 100%;
  }
  .declaration p {
    font-size: 10px;
    margin: 5px;
    line-height: 14px;
    sup {
      font-size: 9px !important;
    }
  }
}


    .nn-redbox{
      background-color: #000;
      color: #fff;
      text-align: center;
      padding: 20px 0px;
    }
    .nn-redbox .h2 {
      margin-bottom: 40px;
      text-transform: uppercase
    }
    .nn-redbox .container{
      padding: 40px;
    }
    .nn-redbox .nn-option-holder{
      cursor: pointer;
      transition: all 0.3s;
      img {
        height: 300px;
        @media (max-width: 800px) {
          height: auto;
          width: 100%;
        }
      }
    }
    @media (max-width: 800px) {
      .nn-redbox .nn-option-holder{
        padding: 10px 0px;
      }
    }
    .nn-redbox .nn-option-holder .nn-option-title{
      text-transform: uppercase;
      font-size: 22px;
      margin: 10px 0px;
      font-weight: 700;
    }
    .nn-redbox .nn-option-holder a {
      text-transform: uppercase;
      padding: 10px 20px;
      width: 100%;
      font-weight: 600;
      font-size: 18px;
      background-color: #fcce01;
      color: #000;
    }
    .nn-redbox .nn-option-holder p {
      color: #fff;
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
      min-height: 105px;
    }
    .nn-redbox .nn-option-holder.active img{
      filter: brightness(70%);
    }
    .nn-redbox .nn-option-holder.active:after {
      content: '';
      height: 50px;
      width: 50px;
      position: absolute;
      top: 118px;
      left: 50%;
      transform: translate(-50%, 0%);
      background-image: url('/wp-content/themes/composer-child/nn-image/tick.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
    .nn-redbox-content {
      padding-top: 40px; 
      width: 600px;
      margin: auto;
    }
    .nn-redbox-content ol {
      font-size: 12px;
    }
    .nn-redbox-content .nn-redbox-content-hr {
      height: 2px;
      background-color: #fff;
      width: 400px;
      margin: 20px auto;
    }
    @media (max-width: 767px) {
      .nn-redbox-content {
        width: 100%;
      }
    }

    .nn-form-holder{
      color: #000;
      text-align: center;
      padding: 20px 0px;
      background-color: #000;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .nn-form-holder .container{
      max-width: 800px;
    }
    @media (max-width: 800px) {
      .nn-form-holder .container{
        max-width: 100%;
      }
    }
    .nn-form-holder .h2{
      text-transform: uppercase;
      color: #fff;
      font-weight: 600;
    }
    .nn-form-holder p{
      color: #fff;
    }
    





