// Font
$primary-font: 'Montserrat', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

// Colours
$color1: #FCCE01;
$color2: #494949;

// Spacing
$col-spacing: 20px;
$spacing: 20px;

// Screen Sizes
$mobile: 768px;
$small-screen: 992px;
$large-screen: 1200px;

// Cookie
$cookie-background: #000;
$cookie-font-color: #fff;
$cookie-button: #FCCE01;