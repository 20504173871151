.homepage	{
	.beermunch {
		align-items: center;
		display: flex;
		padding: 20px 0;
		@media (max-width: 767px) {
			align-items: initial;
			&_top {
				.row {
					justify-content: center;
				}

				.campaign_img_container,
				.timer_container {
					width: 70%;
					padding: 5px;
				}

				.timer_container {
					.beermunch_timer {
						&_text {
							font-size: 12px;
							padding: 10px;
						}
						&_holder {
							padding: 5px;
						}
						.beermunch_time {
							flex: 1;
							text-align: center;
							justify-content: center;
							align-self: center;
							&_title {
								 font-size: 12px;
							}
							&_num {
								font-size: 28px;
							}  				
						}
					}
				}
			}
		}

		.beermunch-btn {
			width: 200px;
		}
	}
}

.beermunch {
	padding: 40px 0px;
	background-image: url('../images/beermunch-background.png');
	background-size: cover;
	background-repeat: no-repeat;
	.col-6 {
		position: relative;
	}
	&_top {
		img {
			width: 100%;
		}
		.beermunch_timer {
			position: absolute;
			top: 50%;
			transform: translate(0%, -50%);
			left: 20px;
			right: 20px;
			@media (max-width: 767px) {
				position: relative;
				top: auto;
				transform: none;
				left: auto;
				right: auto;
			}
			&_text {
				color: #fff;
		    font-size: 22px;
		    padding: 15px 30px;
		    font-weight: 700;
		    text-transform: uppercase;
			}
			&_holder {
				width: 100%;
				padding: 10px;
				border: 8px solid #BB9533;
				display: flex;
				position: relative;
				&:after {
					content: '';
					position: absolute;
					z-index: 9;
					border: 2px solid #000;
					background-color: transparent;
					top: -4px;
					left: -4px;
					right: -4px;
					bottom: -4px;
				}
				.beermunch_time {
					flex: 1;
					text-align: center;
					justify-content: center;
  				align-self: center;
  				&_title {
						color: #fff;
		   			font-size: 22px;
		   			text-transform: uppercase;
  				}
					&_num {
						color: #BB9533;
		   			font-size: 60px;
		   			text-transform: uppercase;
		   			padding: 5px 0px;
		   			@media (max-width: 767px) {
		   				font-size: 50px;
		   			}
  				}  				
				}
			}
		}
	}
	&_bottom {
		text-align: center;
		margin-top: 40px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: -20px;
			height: 2px;
			background-color: #000;
			border-top: 4px solid #BB9533;
			border-bottom: 4px solid #BB9533;
			width: 100%;
			transform: translate(0%, -50%);
			left: 0;
		}
		.pubinthepark_title {
			color: #fff;
	    font-size: 22px;
	    line-height: 32px;
	    font-weight: 700;
	    text-transform: uppercase;
		}
		img {
			height: 120px;
    	margin: 20px 0px;
		}
		ul {
			margin: 0px;
			padding: 0px;
			li {
				list-style-type: none;
				color: #fff;
				font-size: 14px;
			}
		}
		.beermunch_form {
			.grecaptcha-badge, .wpcf7-response-output {
				display: block !important;
		    color: #fff;
		    border: none;
		    text-align: right;
		    margin: 40px 0 0 0;
			}
			.wpcf7-not-valid-tip {
				text-transform: none;
				color: #A9555B;
			}
			p {
				margin: 0px;
				text-align: right;
			}
			label {
				color: #fff;
				font-size: 16px;
				text-transform: uppercase;
				margin: 5px 0px;
				display: block;
				@media (max-width: 1200px) {
					clear: both;
					display: block;
				}
				@media (max-width: 767px) {
					text-align: left;
				}
			}
			input[type="text"], input[type="email"], input[type="tel"]  {
				font-size: 14px;
				font-weight: 200;
				padding: 5px;
				margin-left: 10px;
				width: 300px;
				@media (max-width: 1200px) {
					display: block;
					margin: 0 0 0 auto;
				}
				@media (max-width: 767px) {
					margin: 0px;
					width: 100%;
				}
			}
			input[type="checkbox"] {
				zoom: 2;
			}
			input[type="submit"] {
				border: none;
		    border-radius: 0px;
		    padding: 8px 20px;
		    float: right;
		    font-size: 14px;
		    text-transform: uppercase;
		    margin-top: 10px;
		    @media (max-width: 767px) {
					float: left;
				}
			}
			.terms-check {
				a {
					color: #fff;
					text-decoration: underline;
				}
				.wpcf7-list-item-label {
					display: none;
				}
				.wpcf7-not-valid-tip {
					font-size: 16px !important;
				}
			}
			.wpcf7-list-item {
				margin: 0px;
			}
			select {
				font-size: 14px;
				font-weight: 400;
				padding: 5px;
				margin-left: 10px;
				width: 300px;
				appearance: none;
				color: #000;
				border-radius: 0px;
				@media (max-width: 767px) {
					display: block;
					margin: 0px;
					width: 100%;
				}
			}
			.policy {
				color: #fff;
				font-size: 12px;
				text-transform: uppercase;
				float: left;
				width: calc(100% - 45px);
				text-align: right;
				padding-top: 8px;
			}
		}
	}
	&_info {
		text-align: center;
		p {
			font-size: 12px;
			line-height: 18px;
			margin: 0 0 10px 0;
			color: #fff;
		}
		a {
			color: #fff;
			text-decoration: underline;
			font-size: 12px;
		}
		ul {
			margin: 0px;
			padding: 0px;
			li {
				list-style-type: none;
				color: #fff;
				font-size: 12px;
				padding: 3px 0px;
			}
		}
	}
}

.beermunch-maths-container {
	img {
		width: 100%;
	}
}

.whens-your-beermunch-time,
.beermunch-maths-container {
	margin-top: 40px;
}

.beermunch-maths-container {
	background-position: center;
	background-size: cover;
	width: 100%;
	

	.beermunch-maths {
		display: flex;
		flex-wrap: wrap;
		padding: 20px;
		position: relative;
		min-height: 600px;

		.top-left-img {
			width: 50%;
	
			img {
				width: 100%;
			}
		}
	
		.beermunch-maths-txt {
		
			color: white;
			align-items: center;
	
			ul {
				padding-top: 40px;
				li {
					font-size: 24px;
					text-align: left;
					font-weight: bold;
					margin-bottom: 20px;
				}
			}
		}
	
		.arrow {
			position: absolute;
	    bottom: 31px;
	    right: 0;
	    transform: rotate(26deg);
	    @media (max-width: 992px) {
	    	width: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				bottom: auto;
				right: auto;
				position: relative;
				transform: none;
	    }
	
			img {
				width: 300px;
				margin-bottom: 50px;
			}
		}
	
		.beermunch-maths-btn {
			position: absolute;
			bottom: 90px;
			left: 50%;
			transform: translateX(-50%);
			background-color: #fcce01;
			padding: 20px 30px;
			font-size: 20px;
			text-transform: uppercase;
			font-weight: bold;
			color: black;
			text-align: center;
		}

		@media(max-width: 992px) {
			.arrow {
				img {
					margin: 0 auto;
				}
			}
		}
	
		@media(max-width: $small-screen) {
			flex-direction: column;
			padding-bottom: 200px;
	
			.top-left-img {
				width: 100%;
			}
		
			.beermunch-maths-txt {
				width: 100%;
			}
	
			/*.arrow {
				img {
					width: 100%;
				}
			}*/
		}
	
		@media(max-width: $mobile) {
			.beermunch-maths-btn {
				display: block;
				width: calc(100% - 40px);
			}
		}
	}
}

.beermunch-banner {
	position: relative;
	height: 600px;


	@media (max-width: 1200px) {
		height: 500px; 
	}	
	
	@media (max-width: 768px) {
		height: auto; 
	}

	img {
		width: 100%;
		max-width: 500px;

		@media (max-width: 768px) {
			max-width: 400px;
		}			
	}	

	&-image {
		@media (max-width: 768px) {
			opacity: 0.6;
		}	
	}
	
	.row {
		height: 100%;
	}

	>.row {
		margin: 0;
	}

	.beermunch-background {
		@media (max-width: 768px) {
			display: none;
		}
	}

	.beermunch-content {
		position: absolute;
	    width: 100%;
	    top: 0;
	    text-align: center;

		@media (max-width: 768px) {
			position: relative;
		}	    

	    &-title {
			font-weight: bold;
			text-align: center;
			color: #fff;
			font-size: 36px;
			text-shadow: 0px 0px 10px #000;		
			margin-top: 40px;

			@media (max-width: 1200px) {	
				margin-top: 0px;
			}	


			@media (max-width: 768px) {
				display: none;
			}			

			&-mobile {
				opacity: 0;
				font-weight: bold;
				text-align: center;
				color: #fff;
				font-size: 36px;
				text-shadow: 0px 0px 10px #000;				

				@media (max-width: 768px) {
					opacity: 1;
					padding: 80px 40px;
				}
			}
	   }
	}
}

.whens-your-beermunch-time {
	text-align: center;
	padding-top: 40px;

	p {
		font-size: 32px;
		color: #fff;
	}

	ul {
		display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 20px 0;
    justify-content: center;
    list-style: none;

    li {
    	flex-basis: 25%;
    	padding: 0 20px 20px 20px;

    	&:first-of-type {
    		margin-left: 50px;
    	}

    	img {
    		width: 100%;
    	}
    }

		@media (max-width: 768px) {

	    li {
	    	flex-basis: 50%;
	    	
	    	&:first-of-type {
	    		margin-left: 0;
	    	}
	    }
		}    
	}

	img {
		max-width: 800px;
		width: 100%;
	}
}

.beermunch-text {
	margin: 40px 0px;
	p {
		margin: 10px auto !important;
		font-weight: bold;
		text-align: center;
		line-height: 24px !important;
	}

	.beermunch-highlight {
		font-weight: bold;
		color: #BB9533 !important;
	}

	.font-26 {
		font-size: 26px;
		line-height: 26px !important
	}

	@media(max-width: $mobile) {
			width: 100%;
	}
}

.beermunch-products {
	margin-top: 40px;
	background-color: rgb(29, 29, 29);
	padding: 20px 0;

	.beermunch-product {
		img {
			width: 100%;
		}

		.title {
			text-transform: uppercase;
			color: white;
			font-weight: bold;
			line-height: 20px !important;
		}

		.sub-title {
			color: #BB9533;
			margin: 0;
			font-weight: bold;
		}
	}

	.beermunch-guide {
		text-align: center;

		p {
			text-transform: uppercase;
			font-weight: bold;
			color: white;
			font-size: 18px;
			line-height: 20px !important;
		}

		a {
			display: block;
			width: 100%;
			background-color:#fcce01;
			color: rgb(29, 29, 29);
			text-transform: uppercase;
			padding: 20px 40px;
			font-weight: bold;
			font-size: 24px;
			text-align: center;
		}

		img {
			height: 120px;
			margin: 0 0 20px 0;
		}
	}

	@media(max-width: $mobile) {
		.beermunch-guide {
			a {
				font-size: 18px;
			}
		}
	}
}

.beermunch-video {
	margin-top: 40px;

	p {
/*		font-weight: bold;
		text-align: left;
		margin-left: 20% !important;
		margin-top: 50px !important;
		font-size: 28px;*/

		margin: 40px;
		font-weight: bold;
		text-align: center;
		line-height: 24px !important;
	}

	.video-container {
		position: relative;
		padding-bottom: 56.25%;
		/*padding-top: 30px; */
		height: 0; 
		overflow: hidden;
	}

	.video-container iframe,
	.video-container object,
	.video-container embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	video {
		width: 90%;
		background-color: #000;
	}

	@media(max-width: $mobile) {
		p {
			margin-left: 25px !important;
			margin-top: 0px !important;
		}

		video {
			width: 100%;
		}
	}
}

.beermunch-contact {
	background-color: #000;
}

.beermunch-form {
	background-color: #000;
	padding: 40px;
	margin-top: 40px;

	p {
		color: white !important;
	}

	input {
		width: 50%;
		border: 0;
		font-size: 18px;
		margin: 20px 0;
		padding: 10px;
	}

	input[type=submit] {
		background-color: #fcce01;
		padding: 15px 50px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		border: 0;
		width: 200px;
		margin-top: 0;
		appearance: none;
	}

	.checkbox-holder {
		display: flex;
		width: 70%;
		margin: 0 auto;
		color: white;
		align-items: flex-start;

		input[type=checkbox] {
			display: block;
			width: 20px;
			height: 20px;
		}

		.policy-holder {
			padding: 15px;
			font-size: 12px;

			a {
				color: #fcce01;
			}
		}
	}

	@media(max-width: $mobile) {
		padding: 10px;

		input {
			width: 100%;
		}

		input[type=submit] {
			width: 100%;
		}

		.checkbox-holder {
			width: 100%;
		}
	}
}

.lets-do-the-maths {
	background-color: #000;
	padding: 40px;
	text-align: center;

	.text {
		margin: 10px 135px;

		ul {
			display: inline-block;
			width: 600px;
			padding-left: 20px;

			li {
				color: #fff;
				font-size: 21px;
				font-weight: bold;
				text-align: left;
				margin-bottom: 20px;
			}
		}

		.arrow {
			img {
				width: 150px;
				transform: rotateZ(65deg) rotateY(180deg);
			}
		}
	}

	.columns {
		display: flex;
		justify-content: space-evenly;
	}

	@media(max-width: $large-screen) {
		.text {
			margin: 0;

			ul {
				width: 100%;
				padding: 20px;
			}

			.arrow {
				display: none;
			}
		}
	}

	@media(max-width: $large-screen) {
		.text {
			margin: 0 80px;
		}
	}

	@media(max-width: $mobile) {
		padding: 20px;

		.banner {
			img {
				width: 100%;
			}
		}

		.text {
			margin: 0;
	
			ul {
				width: 100%;
	
				li {
					color: #fff;
					font-size: 21px;
					font-weight: bold;
					text-align: left;
					margin-bottom: 20px;
				}
			}
	
			.arrow {
				display: none;
			}
		}

		.columns {
			.column {
				img {
					width: 100%;
					padding: 5px;
				}
			}
		}
	}
}

.product_sub_title {
	color: #bb9533 !important;
}

