.full-height{
	height: calc(100vh - 95px);
	background-size: cover;
	position: relative;
	background-position: center;
	.title{
		font-size: 48px;
		color: #fff;
		text-transform: uppercase;
		font-weight: 700;
		width: 500px;
		position: absolute;
		top: 150px;
		left: 50%;
		transform: translate(-50%, 0%);
		text-align: center;
		letter-spacing: 4px;
		@media (max-width: $mobile) {
			position: relative;
			left: 0;
			top: 0;
			transform: none;
			width: 100%;
			padding: 0px 20px;
			font-size: 32px;
			padding-top: 120px;
		}
	}

}
.full-slider{
	position: relative;
}
.gallery-prev{
	position: absolute;
	left: 40px;
	top: 50%;
	transform: translate(0%, -50%);
	cursor: pointer;
	outline: none;
	img{
		height: 25px;
	}
}
.gallery-next{
	position: absolute;
	right: 40px;
	top: 50%;
	transform: translate(0%, -50%);
	cursor: pointer;
	outline: none;
	img{
		height: 25px;
	}
}
.btn{
	color: #000;
	background-color: $color1;
	padding: 18px 50px 20px 50px;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 16px;
	text-align: center;
	display: inline-block;
	transition: all 0.3s;
	&:hover, &:focus{
		filter: brightness(85%);
	}
}
.home-btn{
	position: absolute;
	left: 50%;
	bottom: 10%;
	transform: translate(-50%,-50%) translateZ(0);
	@media (max-width: $mobile) {
    font-size: 14px;
    padding: 18px 30px 20px 30px;

  }
}

.chevron-down{
	height: 25px;
	position: absolute;
	bottom: 5%; 
	left: 50%;
	transform: translate(-50%, 0%);
}

.supplier-form{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background-color: rgba(#000, 0.6);
	padding: 40px 80px;
	text-align: center;
	@media (max-width: $mobile) {
		padding: 40px;
	}
	p{
		color: #fff;
		font-weight: 400;
		margin: 0px;
		text-align: center;
	}
	input{
		width: 100%;
		border: 0px;
		font-size: 18px;
		margin: 20px 0px;
		padding: 10px;
	}
	button{
		background-color: $color1;
		padding: 15px 76px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		border: 0px;
	}
}






