html{
	font-family: $primary-font;
}
h1,h2,h3,h4,h5,h6{
	font-family: $primary-font;
}
h1,.h1 { font-size: 32px; }
h2,.h2 { font-size: 28px; }
h3,.h3 { font-size: 24px; }
h4,.h4 { font-size: 20px; }
h5,.h5 { font-size: 18px; }
h6,.h6 { font-size: 16px; }

a, button{
	font-family: $secondary-font;
	text-decoration: none;
	// &:hover, &:focus{
	// 	text-decoration: none;
	// 	filter: brightness(85%);
	// }

}
input{
	font-family: $secondary-font;
}

.subpage p{
	font-size: 20px;
	font-weight: 400;
	color: #000;
	line-height: 28px;
	text-align: center;
	@media (max-width: $mobile) {
		font-size: 16px;
	}
}